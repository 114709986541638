<template>
  <div>
    <v-card>
      <v-flex xs-12>
        <v-card-title class="title">{{
          fiscal_year_id.name
        }}</v-card-title></v-flex
      >
      <hr />
      <v-card-title class="title">
        <v-layout row wrap>
          <v-flex xs12>
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :options.sync="pagination"
              :footer-props="footerProps"
              :server-items-length="items.length"
              hide-default-footer
            >
              <template v-slot:item="{ index, item }">
                <tr>
                  <td>{{ ++index }}</td>
                  <td>
                    {{ item.fee_head }}
                    <p style="font-size: 12px; color: #777; margin-bottom: 5px">
                      <span v-if="item.map_ledger_head"
                        >({{ item.map_ledger_head.asset_ledger }})</span
                      >
                      <span style="color: red" v-else
                        >No Mapping Record Found</span
                      >
                    </p>
                  </td>
                  <td>{{ item.due_amount }}</td>
                  <td>
                    <v-btn
                      small
                      outlined
                      color="primary"
                      :disabled="!item.map_ledger_head"
                      @click="postToAccount(item)"
                    >
                      Send
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import Mixins from "@/library/Mixins";
export default {
	mixins: [Mixins],
	data() {
		return {
			loading: false,
			fiscal_year_id: "",
			pagination: {
				totalItems: 0,
				itemsPerPage: 50,
			},
			headers: [
				{ text: "#", align: "center", value: "id", width: 50, sortable: false },
				{ text: "Fee Head", align: "left", value: "title" },
				{ text: "Due Amount", align: "left", value: "amount" },
				{ text: "Action", align: "left", sortable: false, width: 200 },
			],
			items: [],
			mapFeeHeads: [],
			missing_mapping_records: [],
		};
	},
	watch: {
		pagination: function () {
			this.fetchInvoice();
		},
	},
	mounted() {
		if (["super"].includes(this.$auth.loggedInRole().slug)) {
			this.getFiscalYear();
		}
		this.fetchInvoice();
	},
	methods: {
		fetchInvoice() {
			this.loading = true;
			let extraParam = this.queryString(`school_id=${this.$route.params.id}`);
			this.$rest
				.get(`/api/invoice/details${extraParam}`)
				.then(({ data }) => {
					this.items = data.data.map((res) => {
						return res;
					});
					data.data.map((item) => {
						if (!item.map_ledger_head) {
							this.missing_mapping_records.push(item.fee_head);
						}
					});
					this.pagination.totalItems = data.meta.total;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		postToAccount(item) {
			this.$rest
				.post("/api/opening-ledgers", {
					item,
					fiscalYear: this.fiscal_year_id.id,
					is_billing: true,
				})
				.then((res) => {
					this.$events.fire("notification", {
						message: res.data.message,
						status: "success",
					});
				})
				.catch((err) => {
					this.$events.fire("notification", {
						message: err.message,
						status: "error",
					});
				})
				.finally(() => {});
		},
		getFiscalYear() {
			let extraParam = this.queryString(`school_id=${this.$route.params.id}`);
			this.$rest.get(`/api/fiscal-year${extraParam}`).then(({ data }) => {
				data.data.map((res) => {
					if (res.is_current === true) {
						this.fiscal_year_id = res;
					}
				});
			});
		},
	},
};
</script>
<style scoped>
</style>
